import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebaseConfig';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Skus from './pages/Skus';
import SkusV2 from './pages/SkusV2';
import SkuDetails from './pages/SkuDetails';
import SkuDetailsV2 from './pages/SkuDetailsV2';
import Orders from './pages/Orders';
import OrderDetails from './pages/OrderDetails';
import SellerOrders from './pages/SellerOrders';
import SellerOrderDetails from './pages/SellerOrderDetails';
import PackingSlips from './pages/PackingSlips';
import Sellers from './pages/Sellers';
import SellerDetails from './pages/SellerDetails';
import Species from './pages/Species';
import SpeciesDetails from './pages/SpeciesDetails';
import Regions from './pages/Regions';
import RegionDetails from './pages/RegionDetails';
import Companies from './pages/buyers/Buyers';
import CompanyDetails from './pages/buyers/BuyerDetails';
import Profile from './pages/Profile';
import Users from './pages/Users';
import UserDetails from './pages/UserDetails';
import ConsoleAdmin from './pages/ConsoleAdmin';
import SendOTP from './pages/SendOtp';
import CreateUser from './pages/CreateUser'; // Import the CreateUser component
import CreateBuyer from './pages/CreateBuyer';

import Navbar from './components/Navbar';
import './styles.css';
import { setDarkMode, getStoredTheme } from './themeUtils';
import { TbFishBone } from 'react-icons/tb'; // Import the FishBone icon

const App = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [theme, setThemeState] = useState(getStoredTheme());

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const token = await user.getIdTokenResult();
        const roles = token.claims.role || [];

        if (!roles.includes('hsAdmin')) {
          setUser(null);
          auth.signOut();
        } else {
          setUser(user);
        }
      } else {
        setUser(null);
      }
      setLoading(false); // Set loading to false once auth state is determined
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    setDarkMode(theme);
  }, [theme]);

  if (loading) {
    return <div></div>; // Display a loading indicator while checking auth state
  }

  return (
    <Router>
      {user && <Navbar theme={theme} setTheme={setThemeState} />}
      <div className="content">
        <Routes>
          <Route path="/send-otp" element={<SendOTP />} />
          <Route path="/login" element={!user ? <Login /> : <Navigate to="/" />} />
          <Route path="/" element={user ? <Dashboard theme={theme} /> : <Navigate to="/login" />} />
          <Route path="/skus" element={user ? <Skus /> : <Navigate to="/login" />} />
          <Route path="/skusV2" element={user ? <SkusV2 /> : <Navigate to="/login" />} />
          <Route path="/sku-details" element={user ? <SkuDetails /> : <Navigate to="/login" />} />
          <Route path="/sku-details-v2" element={user ? <SkuDetailsV2 /> : <Navigate to="/login" />} />
          <Route path="/orders" element={user ? <Orders /> : <Navigate to="/login" />} />
          <Route path="/order-details" element={user ? <OrderDetails /> : <Navigate to="/login" />} />
          <Route path="/seller-orders" element={user ? <SellerOrders /> : <Navigate to="/login" />} />
          <Route path="/seller-order-details" element={user ? <SellerOrderDetails /> : <Navigate to="/login" />} />
          <Route path="/packing-slips" element={user ? <PackingSlips /> : <Navigate to="/login" />} />
          <Route path="/sellers" element={user ? <Sellers /> : <Navigate to="/login" />} />
          <Route path="/seller-details" element={user ? <SellerDetails /> : <Navigate to="/login" />} />
          <Route path="/species" element={user ? <Species /> : <Navigate to="/login" />} />
          <Route path="/species-details" element={user ? <SpeciesDetails /> : <Navigate to="/login" />} />
          <Route path="/regions" element={user ? <Regions /> : <Navigate to="/login" />} />
          <Route path="/region-details" element={user ? <RegionDetails /> : <Navigate to="/login" />} />
          <Route path="/buyers" element={user ? <Companies /> : <Navigate to="/login" />} />
          <Route path="/buyer-details" element={user ? <CompanyDetails /> : <Navigate to="/login" />} />
          <Route path="/users" element={user ? <Users /> : <Navigate to="/login" />} />
          <Route path="/user-details" element={user ? <UserDetails /> : <Navigate to="/login" />} />
          <Route path="/create-user" element={user ? <CreateUser /> : <Navigate to="/login" />} /> {/* Add this route */}
          <Route path="/create-buyer" element={user ? <CreateBuyer /> : <Navigate to="/login" />} /> {/* Add this route */}
          <Route path="/console-admin" element={user ? <ConsoleAdmin /> : <Navigate to="/login" />} />
          <Route path="/profile" element={user ? <Profile /> : <Navigate to="/login" />} />
          <Route path="/logging-out" element={<LoggingOut />} />
        </Routes>
      </div>
    </Router>
  );
};

const LoggingOut = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = '/login';
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="loading-container">
      <TbFishBone className="loading-icon" />
    </div>
  );
};

export default App;
