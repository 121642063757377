import React, { useEffect, useState } from 'react';
import { Container, Card, Button, Row, Col, ListGroup, Form } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebaseConfig';
import { FaArrowLeft } from 'react-icons/fa';

const ProducerOrderDetail = () => {
  const [producerOrder, setProducerOrder] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducerOrder = async () => {
      const query = new URLSearchParams(location.search);
      const producerCode = query.get('sellerCode');

      try {
        const getProducerOrdersByCode = httpsCallable(functions, 'getProducerOrdersByCode');
        const response = await getProducerOrdersByCode({ producerCode });
        setProducerOrder(response.data.producerOrder);
      } catch (error) {
        console.error('Error fetching producer order:', error);
      }
    };

    fetchProducerOrder();
  }, [location]);

  if (!producerOrder) {
    return <p>Loading...</p>;
  }

  return (
    <Container className="content">
      <Button variant="secondary" onClick={() => navigate('/seller-orders')}>
        <FaArrowLeft className="me-2 mb-1" />
        Back to Orders
      </Button>

      <h1 className="mt-3">Producer Order Details</h1>

      <Card className="mb-3">
        <Card.Header>Order Information</Card.Header>
        <Card.Body>
          <Row>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Seller</Form.Label>
                <Form.Control type="text" value={producerOrder.seller} readOnly />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Status</Form.Label>
                <Form.Control type="text" value={producerOrder.producerOrderStatus} readOnly />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>Shipping Date</Form.Label>
                <Form.Control type="text" value={new Date(producerOrder.shippingDate).toLocaleDateString()} readOnly />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Producer Code</Form.Label>
                <Form.Control type="text" value={producerOrder.producerCode} readOnly />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>Buyer Company</Form.Label>
                <Form.Control type="text" value={producerOrder.buyerCompanyName} readOnly />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Delivery Address</Form.Label>
                <Form.Control
                  type="text"
                  value={`${producerOrder.deliveryAddress.addressLine1}, ${producerOrder.deliveryAddress.addressLine2}, ${producerOrder.deliveryAddress.city}, ${producerOrder.deliveryAddress.region}, ${producerOrder.deliveryAddress.postcode}, ${producerOrder.deliveryAddress.country}`}
                  readOnly
                />
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card>
        <Card.Header>SKUs</Card.Header>
        <ListGroup variant="flush">
          {producerOrder.skus.map((sku, index) => (
            <ListGroup.Item key={index}>
              <Row>
                <Col md={4}>
                  <strong>Sku Name:</strong> {sku.skuName}
                </Col>
                <Col md={2}>
                  <strong>Quantity:</strong> {sku.quantity} {sku.uom}
                </Col>
                <Col md={4}>
                  <strong>Substitutions:</strong> {sku.substitutionNotes || 'N/A'}
                </Col>
                <Col md={2}>
                  <strong>Notes:</strong> {sku.notes || 'N/A'}
                </Col>
              </Row>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Card>
    </Container>
  );
};

export default ProducerOrderDetail;
