// src/pages/SkuDetailsV2.js

import React, { useState, useEffect } from 'react';
import { Container, Button, Spinner, Card, Row, Col, Alert } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { getSkuById, updateSku } from '../services/skuServiceV2';
import { getSellers } from '../services/sellerService';
import { getRegions } from '../services/regionService';
import { getSpecies } from '../services/speciesService';
import InputFieldRenderer from '../components/InputFieldRenderer';
import { filterUpdatableFields, updatableFields } from '../utils/skuFieldUtils';

const SkuDetails = ({ sku, sellers = [], regions = [], species = [] }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedSku, setEditedSku] = useState(sku);
  const [isSaving, setIsSaving] = useState(false);
  const [alert, setAlert] = useState({ show: false, message: '', variant: '' });

  useEffect(() => {
    // Update initial values of dropdowns based on SKU data
    if (sku && sellers.length && regions.length && species.length) {

      const foundRegion = regions.find((region) => region.regionId === sku.appFields.sellerRegionId);
      const foundSeller = sellers.find((seller) => seller.sellerId === sku.appFields.sellerId);
      const foundSpecies = species.find((specie) => specie.speciesId === sku.appFields.speciesId);

      setEditedSku((prev) => ({
        ...prev,
        appFields: {
          ...prev.appFields,
          sellerId: foundSeller ? foundSeller.sellerId : null,
          sellerRegionId: foundRegion ? foundRegion.regionId : null,
          speciesId: foundSpecies ? foundSpecies.speciesId : null,
        },
      }));

      //console.log('setting the region name to', foundRegion ? foundRegion.regionName : 'not found', 'in the dropdown');
    }
  }, [sku, sellers, regions, species]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setEditedSku(sku); // Reset to original values
  };

  const handleSaveClick = async () => {
    setIsSaving(true);
    setAlert({ show: false });

    try {
      const filteredSkuData = filterUpdatableFields(editedSku);
      const response = await updateSku(sku.appFields.skuId, filteredSkuData);
      setAlert({ show: true, message: response.message, variant: 'success' });
      window.location.reload(); // Refresh the page to get the updated data
    } catch (error) {
      console.error('Error saving SKU:', error);
      setAlert({
        show: true,
        message: error.message || 'An error occurred while saving the SKU.',
        variant: 'danger',
      });
    } finally {
      setIsSaving(false);
      setIsEditing(false);
    }
  };

  const handleInputChange = (category, field, value) => {
    setEditedSku((prevSku) => ({
      ...prevSku,
      [category]: {
        ...prevSku[category],
        [field]: value,
      },
    }));
  };

  const renderTableRows = (fields = {}, category) => {
    return Object.entries(fields).map(([field, value]) => (
      <Row key={`${category}-${field}`} className="mb-2">
        <Col xs={4} style={{ fontWeight: 'bold', textTransform: 'uppercase', fontSize: '10pt' }}>
          {field.replace(/([A-Z])/g, ' $1')}
        </Col>
        <Col xs={8}>
          <InputFieldRenderer
            field={field}
            value={value}
            category={category}
            handleInputChange={handleInputChange}
            isEditing={isEditing && updatableFields.includes(field)}
            sellers={sellers}
            regions={regions}
            species={species}
          />
        </Col>
      </Row>
    ));
  };

  return (
    <Container className="mt-4">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h1>SKU Details</h1>
        {!isEditing ? (
          <Button variant="primary" onClick={handleEditClick}>
            Edit
          </Button>
        ) : (
          <>
            <Button variant="success" onClick={handleSaveClick} disabled={isSaving}>
              {isSaving ? (
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              ) : (
                'Save'
              )}
            </Button>
            <Button
              variant="secondary"
              onClick={handleCancelClick}
              className="ms-2"
              disabled={isSaving}
            >
              Cancel
            </Button>
          </>
        )}
      </div>

      {alert.show && <Alert variant={alert.variant} className="mt-2">{alert.message}</Alert>}

      <Card className="mb-3">
        <Card.Header>App Fields</Card.Header>
        <Card.Body>{renderTableRows(editedSku.appFields, 'appFields')}</Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Header>Shipping Fields</Card.Header>
        <Card.Body>{renderTableRows(editedSku.shippingFields, 'shippingFields')}</Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Header>Other Fields</Card.Header>
        <Card.Body>{renderTableRows(editedSku.otherFields, 'otherFields')}</Card.Body>
      </Card>
    </Container>
  );
};

const SkuDetailsPage = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const skuId = params.get('skuId');
  const [sku, setSku] = useState(null);
  const [sellers, setSellers] = useState([]);
  const [regions, setRegions] = useState([]);
  const [species, setSpecies] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [skuResponse, sellersResponse, regionsResponse, speciesResponse] = await Promise.all([
          getSkuById(skuId),
          getSellers('summary'),
          getRegions('summary'),
          getSpecies('summary'),
        ]);

        if (skuResponse && skuResponse.sku) {
          setSku(skuResponse.sku);
        }
        setSellers(sellersResponse?.sellers || []);
        setRegions(regionsResponse?.regions || []);
        setSpecies(speciesResponse?.species || []);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    if (skuId) {
      fetchData();
    } else {
      setLoading(false);
    }
  }, [skuId]);

  if (loading) {
    return (
      <Container className="mt-4 d-flex justify-content-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  return sku ? (
    <SkuDetails sku={sku} sellers={sellers} regions={regions} species={species} />
  ) : (
    <p>SKU not found</p>
  );
};

export default SkuDetailsPage;
