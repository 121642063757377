// src/services/skuService.js

import { httpsCallable } from "firebase/functions";
//import { getFunctions, httpsCallable } from "firebase/functions";
import { functions } from "../firebaseConfig";  // Assuming you have a firebase.js file where you initialize Firebase

export const getSkus = async () => {
  const getAllSkus = httpsCallable(functions, 'getAllSkusV2');
  const response = await getAllSkus();
  const skus = response.data.skus;

  // Sort SKUs based on the specified hierarchy
  skus.sort((a, b) => {
    // Helper function to safely compare strings, treating null/undefined as empty strings
    const safeCompare = (str1, str2) => {
      return (str1 || '').localeCompare(str2 || '');
    };

    // Compare based on hierarchy
    const nameComparison = safeCompare(a.skuName, b.skuName);
    if (nameComparison !== 0) return nameComparison;

    const cutComparison = safeCompare(a.cut, b.cut);
    if (cutComparison !== 0) return cutComparison;

    const packFormatComparison = safeCompare(a.packFormat, b.packFormat);
    if (packFormatComparison !== 0) return packFormatComparison;

    const gradeComparison = safeCompare(a.grade, b.grade);
    if (gradeComparison !== 0) return gradeComparison;

    return safeCompare(a.unitSizeName, b.unitSizeName);
  });

  return skus;
};


export const getSkuById = async (skuId) => {
  const getSkuByIdCallable = httpsCallable(functions, 'getSkuById');
  const response = await getSkuByIdCallable({ skuId });
  return response.data.sku;
};


export const updateSku = async (skuId, skuData) => {
  const updateSkuCallable = httpsCallable(functions, 'updateSku');
  const response = await updateSkuCallable({ skuId, ...skuData });
  return response.data;
};

// src/services/skuService.js

export const getStats = async () => {
  const getSkuStats = httpsCallable(functions, 'getSkuStats');
  const response = await getSkuStats();
  if (!response.data.success) {
    throw new Error('Failed to fetch stats');
  }
  return response.data;
};

