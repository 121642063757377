import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import Select from 'react-select'; // Import react-select
import SkuItem from './SkuItem'; // Import the SkuItem component

const ProducerOrderCard = ({
  order,
  handleSkuClick,
  //packedDetails,
  skus,
  isEditing, // Pass down the isEditing prop
}) => {
  // Filter SKUs based on the sellerId matching the order's sellerId
  const filteredSkus = skus.filter((sku) => sku.sellerId === order.sellerId);

  // Options for order status
  const statusOptions = [
    { value: 'AWAITING_PROCESSING', label: 'AWAITING PROCESSING' },
    { value: 'PROCESSING', label: 'PROCESSING' },
    { value: 'PREPARING_FOR_SHIPMENT', label: 'PREPARING FOR SHIPMENT' },
    { value: 'OUT_FOR_DELIVERY', label: 'OUT FOR DELIVERY' },
    { value: 'DELIVERED', label: 'DELIVERED' },
  ];

  // Find the selected status option based on the current producerOrderStatus value
  const selectedStatus = statusOptions.find(
    (option) => option.value === order.producerOrderStatus
  );

  // Handle change event for status select
  const handleStatusChange = (selectedOption) => {
    // Update the status in the order object
    order.producerOrderStatus = selectedOption.value;
  };

  return (
    <Card className="mb-3">
      <Card.Header>
        Producer Order: {order.seller} ({order.sellerId})
      </Card.Header>
      <Card.Body>
        <Row className="g-3 d-flex align-items-stretch mb-3">
          {/* First Row */}
          <Col xs={12} md={6} className="d-flex">
            <Card className="flex-fill rounded" style={{ borderRight: '1px solid #dee2e6' }}>
              <Card.Body>
                <div className="d-flex align-items-center mb-2">
                  <strong className="me-2">Status:</strong>
                  <Select
                    options={statusOptions}
                    value={selectedStatus}
                    onChange={handleStatusChange}
                    isDisabled={!isEditing} // Disable the select when not in edit mode
                    className="flex-grow-1"
                  />
                </div>
                <p><strong>Substitution Needed:</strong> {order.skus.some(sku => sku.substitutionNotes) ? 'YES' : 'NO'}</p>
                <p><strong>Substitution Notes:</strong> {order.skus.map(sku => sku.substitutionNotes || 'None').join(', ')}</p>
                <p><strong>Order Notes:</strong> {order.skus.map(sku => sku.orderNotes || 'None').join(', ')}</p>
              </Card.Body>
            </Card>
          </Col>

          <Col xs={12} md={6} className="d-flex">
            <Card className="flex-fill rounded" style={{ borderLeft: '1px solid #dee2e6' }}>
              <Card.Body>
                <p><strong>Producer Order ID:</strong> {order.producerOrderId}</p>
                <p><strong>Created:</strong> {new Date(order.createdAt).toLocaleString()}</p>
                <p><strong>Last Updated:</strong> {new Date(order.updatedAt).toLocaleString()}</p>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {order.skus.map((sku, skuIndex) => (
          <SkuItem
            orderId={order.orderId}
            producerOrderId={order.producerOrderId}
            producerOrder={order}
            key={skuIndex}
            sku={sku}
            skus={filteredSkus} // Pass only the filtered SKUs to SkuItem
            handleSkuClick={handleSkuClick}
            //packedDetail={packedDetails[sku.skuId] || {}}
            isEditing={isEditing} // Pass down isEditing state to SkuItem
          />
        ))}
      </Card.Body>
    </Card>
  );
};

export default ProducerOrderCard;
