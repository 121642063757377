import React from 'react';
import { Modal, Button, Spinner, Alert, Table } from 'react-bootstrap';

const SkuDetailsModal = ({ showModal, setShowModal, loading, error, selectedSku }) => {
  return (
    <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>SKU Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div className="d-flex flex-column align-items-center">
            <Spinner animation="border" role="status" className="mb-3">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            <p>Loading SKU details...</p>
          </div>
        ) : error ? (
          <Alert variant="danger">{error}</Alert>
        ) : selectedSku ? (
          <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Field</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(selectedSku).map(([key, value]) => (
                  <tr key={key}>
                    <td>
                      {key.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}
                    </td>
                    <td>{value?.toString() || 'N/A'}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        ) : (
          <p>No SKU details available.</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowModal(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SkuDetailsModal;
