import React, { useEffect, useState, useRef } from 'react';
import { Container, Table, FormControl, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FaSortUp, FaSortDown, FaSort } from 'react-icons/fa';
import { getSkus } from '../services/skuService';
import '../styles.css';

const Skus = () => {
  const [skus, setSkus] = useState([]);
  const [filteredSkus, setFilteredSkus] = useState([]);
  const [searchTerm, setSearchTerm] = useState(localStorage.getItem('searchTerm') || '');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [loading, setLoading] = useState(true); // New state for loading
  const navigate = useNavigate();
  const tableRef = useRef(null);
  const initialRender = useRef(true);

  useEffect(() => {
    const fetchSkus = async () => {
      try {
        const skusData = await getSkus();
        setSkus(skusData);
        setFilteredSkus(skusData);
      } catch (error) {
        console.error('Error fetching SKUs:', error);
      } finally {
        setLoading(false); // Stop loading when the fetch is done
      }
    };

    fetchSkus();
  }, []);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }

    const results = skus.filter((sku) =>
      Object.values(sku).some(
        (value) =>
          value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    setFilteredSkus(results);
    localStorage.setItem('searchTerm', searchTerm);
  }, [searchTerm, skus]);

  useEffect(() => {
    if (sortConfig.key) {
      let sortedSkus = [...filteredSkus];
      if (sortConfig.direction) {
        sortedSkus.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'asc' ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'asc' ? 1 : -1;
          }
          return 0;
        });
      } else {
        sortedSkus = skus; // If no direction, reset to the original order
      }
      setFilteredSkus(sortedSkus);
    }
  }, [sortConfig, skus, filteredSkus]);

  const handleRowClick = (skuId) => {
    navigate(`/sku-details?skuId=${skuId}`);
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key) {
      if (sortConfig.direction === 'asc') {
        direction = 'desc';
      } else if (sortConfig.direction === 'desc') {
        direction = null;
      }
    }
    setSortConfig({ key, direction });
  };

  const getSortIndicator = (key) => {
    if (sortConfig.key === key) {
      if (sortConfig.direction === 'asc') {
        return <FaSortUp />;
      } else if (sortConfig.direction === 'desc') {
        return <FaSortDown />;
      }
    }
    return <FaSort />;
  };

  if (loading) {
    return (
      <Container className="content d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  return (
    <Container className="content">
      <h1>SKUs V1 Page</h1>
      <div className="d-flex mb-3">
        <FormControl
          type="text"
          placeholder="Search"
          className="me-2"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <Table striped bordered hover ref={tableRef} className="table-responsive">
        <thead>
          <tr>
            {['subCategory', 'speciesBreed', 'skuIdentifier', 'sellerName', 'price', 'uom'].map((key) => (
              <th
                key={key}
                onClick={() => handleSort(key)}
                style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}
                className={`col-${key}`}
              >
                <div className="d-flex justify-content-between align-items-center">
                  {key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}&nbsp;&nbsp;
                  {getSortIndicator(key)}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {filteredSkus.map((sku) => (
            <tr key={sku.skuId} onClick={() => handleRowClick(sku.skuId)} style={{ cursor: 'pointer' }}>
              <td className="col-subCategory">{sku.subCategory}</td>
              <td className="col-speciesBreed">{sku.speciesBreed}</td>
              <td className="col-skuIdentifier">{sku.skuIdentifier}</td>
              <td className="col-sellerName">{sku.sellerName}</td>
              <td className="col-price">{sku.price}</td>
              <td className="col-uom">{sku.uom}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default Skus;
