import React from 'react';
import { Container } from 'react-bootstrap';
import '../styles.css';

const Dashboard = () => {
  return (
    <Container 
      className="d-flex justify-content-center align-items-center"
      style={{ height: '100vh', textAlign: 'center' }}
    >
      <div>
        <h1 style={{ fontSize: '3rem' }}>This is the dashboard.</h1>
        <p style={{ fontSize: '2rem' }}>Great things will happen here.</p>
      </div>
    </Container>
  );
};

export default Dashboard;
