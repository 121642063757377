import React, { useState } from 'react';
import { functions } from '../firebaseConfig'; // Adjust the path as needed
import { httpsCallable } from 'firebase/functions';

const SendOTP = () => {
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [status, setStatus] = useState('');

  const handleSendEmailOTP = async () => {
    setStatus('Sending OTP via Email...');
    const sendEmailOtp = httpsCallable(functions, 'sendEmailOTP');
    try {
      const result = await sendEmailOtp({ email });
      if (result.data.success) {
        setStatus('OTP sent successfully via Email!');
      } else {
        setStatus('Failed to send OTP via Email: ' + result.data.error);
      }
    } catch (error) {
      if (error.code === 'failed-precondition') {
        setStatus('Please wait 10 seconds before requesting another OTP via Email.');
      } else {
        setStatus('Error: ' + error.message);
      }
    }
  };

  const handleSendSmsOTP = async () => {
    setStatus('Sending OTP via SMS...');
    const sendSmsOtp = httpsCallable(functions, 'sendSmsOTP');
    try {
      const result = await sendSmsOtp({ phoneNumber });
      if (result.data.success) {
        setStatus('OTP sent successfully via SMS!');
      } else {
        setStatus('Failed to send OTP via SMS: ' + result.data.error);
      }
    } catch (error) {
      if (error.code === 'failed-precondition') {
        setStatus('Please wait 10 seconds before requesting another OTP via SMS.');
      } else {
        setStatus('Error: ' + error.message);
      }
    }
  };

  const handleVerifyOTP = async () => {
    setStatus('Verifying OTP...');
    const verifyEmailOtp = httpsCallable(functions, 'verifyEmailOTP');
    try {
      const result = await verifyEmailOtp({ email, otp });
      if (result.data.verified) {
        setStatus('OTP verified successfully!');
      } else {
        setStatus('Failed to verify OTP: ' + result.data.message);
      }
    } catch (error) {
      setStatus('Error: ' + error.message);
    }
  };

  return (
    <div>
      <h2>Send OTP via Email</h2>
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Enter your email"
      />
      <button onClick={handleSendEmailOTP}>Send OTP</button>

      <h2>Send OTP via SMS</h2>
      <input
        type="text"
        value={phoneNumber}
        onChange={(e) => setPhoneNumber(e.target.value)}
        placeholder="Enter your phone number"
      />
      <button onClick={handleSendSmsOTP}>Send OTP</button>

      <h2>Verify OTP</h2>
      <input
        type="text"
        value={otp}
        onChange={(e) => setOtp(e.target.value)}
        placeholder="Enter your OTP"
      />
      <button onClick={handleVerifyOTP}>Verify OTP</button>

      <p>{status}</p>
    </div>
  );
};

export default SendOTP;
