// src/pages/ConsoleAdmin.js
import React, { useEffect, useState } from 'react';
import { Container, Table } from 'react-bootstrap';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebaseConfig';
import '../styles.css';

const ConsoleAdmin = () => {
  const [user, setUser] = useState(null);
  const [envVars, setEnvVars] = useState({});
  const [environment, setEnvironment] = useState('');

  useEffect(() => {
    // Get the current user
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        // Fetch ID token and user details
        const idToken = await currentUser.getIdToken();
        setUser({
          idToken,
          userId: currentUser.uid,
          email: currentUser.email,
          displayName: currentUser.displayName
        });
      }
    });

    // Get environment variables
    setEnvVars({
      REACT_APP_FIREBASE_API_KEY: process.env.REACT_APP_FIREBASE_API_KEY,
      REACT_APP_FIREBASE_AUTH_DOMAIN: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      REACT_APP_FIREBASE_PROJECT_ID: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      REACT_APP_FIREBASE_STORAGE_BUCKET: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      REACT_APP_FIREBASE_MESSAGING_SENDER_ID: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      REACT_APP_FIREBASE_APP_ID: process.env.REACT_APP_FIREBASE_APP_ID,
      REACT_APP_FIREBASE_FUNCTIONS_REGION: process.env.REACT_APP_FIREBASE_FUNCTIONS_REGION,
      REACT_APP_USE_EMULATOR: process.env.REACT_APP_USE_EMULATOR,
      REACT_APP_FIREBASE_FUNCTIONS_EMULATOR_HOST: process.env.REACT_APP_FIREBASE_FUNCTIONS_EMULATOR_HOST
    });

    // Determine the environment
    if (process.env.REACT_APP_USE_EMULATOR === 'true') {
      setEnvironment('Local Emulator');
    } else if (process.env.REACT_APP_FIREBASE_PROJECT_ID.includes('production')) {
      setEnvironment('Production');
    } else if (process.env.REACT_APP_FIREBASE_PROJECT_ID.includes('staging')) {
      setEnvironment('Staging');
    } else {
      setEnvironment('Development');
    }

    // Clean up subscription
    return () => unsubscribe();
  }, []);

  return (
    <Container className="content">
      <h1>Console Admin Page</h1>
      <h2>Debug Information</h2>
      <div>
        <h3>Current User</h3>
        {user ? (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Property</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>ID Token</td>
                <td>{user.idToken}</td>
              </tr>
              <tr>
                <td>User ID</td>
                <td>{user.userId}</td>
              </tr>
              <tr>
                <td>Email</td>
                <td>{user.email}</td>
              </tr>
              <tr>
                <td>Display Name</td>
                <td>{user.displayName}</td>
              </tr>
            </tbody>
          </Table>
        ) : (
          <p>No user signed in.</p>
        )}
      </div>
      <div>
        <h3>Environment Variables</h3>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Variable</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(envVars).map(([key, value]) => (
              <tr key={key}>
                <td>{key}</td>
                <td>{value}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <div>
        <h3>Current Environment</h3>
        <p>{environment}</p>
      </div>
    </Container>
  );
};

export default ConsoleAdmin;
