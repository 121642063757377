import React, { useEffect, useState, useRef } from 'react';
import { Container, Table, FormControl, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FaSortUp, FaSortDown, FaSort } from 'react-icons/fa';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebaseConfig';
import '../styles.css';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const initialRender = useRef(true);

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      const getUsers = httpsCallable(functions, 'getUsers');
      try {
        const response = await getUsers();
        const usersData = response.data.users;
        setUsers(usersData);
        setFilteredUsers(usersData);
      } catch (error) {
        console.error('Error fetching users:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }

    const results = users.filter((user) =>
      Object.values(user).some(
        (value) =>
          value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    setFilteredUsers(results);
  }, [searchTerm, users]);

  useEffect(() => {
    if (sortConfig.key) {
      let sortedUsers = [...filteredUsers];
      if (sortConfig.direction) {
        sortedUsers.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'asc' ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'asc' ? 1 : -1;
          }
          return 0;
        });
      } else {
        sortedUsers = users; // If no direction, reset to the original order
      }
      setFilteredUsers(sortedUsers);
    }
  }, [sortConfig, users, filteredUsers]);

  const handleRowClick = (userId) => {
    navigate(`/user-details?userId=${userId}`);
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key) {
      if (sortConfig.direction === 'asc') {
        direction = 'desc';
      } else if (sortConfig.direction === 'desc') {
        direction = null;
      }
    }
    setSortConfig({ key, direction });
  };

  const getSortIndicator = (key) => {
    if (sortConfig.key === key) {
      if (sortConfig.direction === 'asc') {
        return <FaSortUp />;
      } else if (sortConfig.direction === 'desc') {
        return <FaSortDown />;
      }
    }
    return <FaSort />;
  };

  if (loading) {
    return (
      <Container className="content d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  return (
    <Container className="content">
      <h1>Users Page</h1>
      <div className="d-flex mb-3">
        <FormControl
          type="text"
          placeholder="Search"
          className="me-2"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <Table striped bordered hover className="table-responsive">
        <thead>
          <tr>
            {['email', 'firstName', 'lastName', 'role', 'jobTitle'].map((key) => (
              <th
                key={key}
                onClick={() => handleSort(key)}
                style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}
                className={`col-${key}`}
              >
                <div className="d-flex justify-content-between align-items-center">
                  {key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}&nbsp;&nbsp;
                  {getSortIndicator(key)}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {filteredUsers.map((user) => (
            <tr key={user.userId} onClick={() => handleRowClick(user.userId)} style={{ cursor: 'pointer' }}>
              <td className="col-email">{user.email}</td>
              <td className="col-firstName">{user.firstName}</td>
              <td className="col-lastName">{user.lastName}</td>
              <td className="col-role">{user.role}</td>
              <td className="col-jobTitle">{user.jobTitle}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default Users;
