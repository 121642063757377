import React, { useEffect, useState } from 'react';
import { Container, Spinner, Alert, Button } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom'; // Import useLocation and useNavigate hooks
import { FaArrowLeft } from 'react-icons/fa'; // Import icons
import OrderChargesSummaryComponent from '../components/OrderChargesSummaryComponent';
import ProducerOrderComponent from '../components/ProducerOrderComponent';
import OrderOverviewComponent from '../components/OrderOverviewComponent';
import { getOrderById } from '../services/orderService'; // Import the order service
import { getSkus } from '../services/skuService'; // Import the SKUs service

const OrderPage = () => {
  const location = useLocation(); // Use useLocation to get the current location
  const navigate = useNavigate();
  const [order, setOrder] = useState(null);
  //const [backupOrder, setBackupOrder] = useState(null);
  const [skus, setSkus] = useState([]); // State to store SKUs
  const [packedItems, setPackedItems] = useState([]); // State to store packed items
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  //const [isEditing, setIsEditing] = useState(false);
  //const [isSaving, setIsSaving] = useState(false);
  //const [isSaved, setIsSaved] = useState(false);
  //const [saveError, setSaveError] = useState(null);

  useEffect(() => {
    const fetchOrderAndSkus = async () => {
      const query = new URLSearchParams(location.search);
      const orderId = query.get('orderId');

      if (!orderId) {
        setError('Order ID is missing from the URL.');
        setLoading(false);
        return;
      }

      try {
        // Fetch order data
        const orderData = await getOrderById(orderId);

        // Fetch SKUs
        const skusData = await getSkus(); // Call the getSkus service method
        setSkus(skusData);

        // Extract packed items from the order data
        const packedItemsArray = extractPackedItems(orderData);
        setPackedItems(packedItemsArray);

        setOrder(orderData);
        //setBackupOrder(orderData); // Backup the order for cancel functionality
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchOrderAndSkus();
  }, [location]);

  // Function to extract packed items from the order data
  const extractPackedItems = (order) => {
    const items = [];

    // Traverse through producerOrders to collect packedSkuId and packedQuantity pairs
    if (order?.orderDetails?.producerOrders) {
      order.orderDetails.producerOrders.forEach((producerOrder) => {
        if (producerOrder.skus) {
          producerOrder.skus.forEach((sku) => {
            // Check if packedSkuId and packedQuantity exist, then push to items array
            if (sku.packedSkuId && sku.packedQuantity) {
              items.push({
                skuId: sku.packedSkuId,
                quantity: sku.packedQuantity,
              });
            }
          });
        }
      });
    }

    return items;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setOrder({ ...order, [name]: value });
  };

  // const handleEdit = () => {
  //   setBackupOrder(order); // Store the current state in case of cancel
  //   setIsEditing(true);
  // };

  // const handleCancel = () => {
  //   setOrder(backupOrder); // Restore the backup state
  //   setIsEditing(false);
  // };

  // const handleSave = async () => {
  //   setIsSaving(true);
  //   setIsSaved(false);
  //   setSaveError(null);

  //   try {
  //     await updateOrder(order.orderId, {
  //       // Add necessary fields to update
  //       orderStatus: order.orderStatus,
  //       orderDetails: order.orderDetails,
  //     });
  //     setIsSaved(true);
  //     setIsEditing(false);
  //   } catch (error) {
  //     console.error('Error saving Order:', error);
  //     setSaveError(error.message); // Store the error message
  //   } finally {
  //     setIsSaving(false);
  //   }
  // };

  // const handleExportToCSV = () => {
  //   // Implement the export to CSV functionality here
  //   console.log('Exporting order to CSV...');
  // };

  const handleBack = () => {
    navigate('/orders');
  };

  if (loading) {
    return (
      <Container className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Alert variant="danger">Error: {error}</Alert>
      </Container>
    );
  }

  if (!order) {
    return (
      <Container>
        <Alert variant="warning">No order data available.</Alert>
      </Container>
    );
  }

  // Destructure the order object to pass the required data to each component
  const { 
    createdAt, 
    orderId, 
    orderStatus, 
    updatedAt, 
    orderSummary, 
    orderDetails, 
    deliveryAddress 
  } = order;

  return (
    <Container className="content">
      <h1>Order Details</h1>
      <div className="d-flex justify-content-between mb-3">
        <Button variant="secondary" 
        onClick={handleBack} 
        //disabled={isSaving}
        >
          <FaArrowLeft className="me-2 mb-1" />
          Back
        </Button>
        {/*}
        <div>
          {isEditing ? (
            <>
              <Button variant="danger" onClick={handleCancel} disabled={isSaving} className="me-3">
                <FaTimes className="me-2 mb-1" />
                Cancel
              </Button>
              <Button variant="primary" onClick={handleSave} disabled={isSaving}>
                {isSaving ? (
                  <span className="spinner-border spinner-border-sm"></span>
                ) : (
                  <>
                    <FaSave className="me-2 mb-1" />
                    Save
                  </>
                )}
              </Button>
            </>
          ) : (
            <>
              <Button variant="secondary" onClick={handleEdit} disabled={isSaving} className="me-3">
                <FaEdit className="me-2 mb-1" />
                Edit
              </Button>
              <Button variant="success" onClick={handleExportToCSV}>
                <FaFileExport className="me-2 mb-1" />
                Export to CSV
              </Button>
            </>
          )}
        </div> */}
      </div>
      {/*{isSaved && <Alert variant="success">Order has been saved successfully.</Alert>}*/}
      {/*{saveError && <Alert variant="danger">{saveError}</Alert>}*/}

      <OrderOverviewComponent 
        orderDetails={orderDetails} 
        deliveryAddress={deliveryAddress}
        buyerCompanyName={orderDetails.buyerCompanyName}
        createdAt={createdAt}
        orderId={orderId}
        orderStatus={orderStatus}
        updatedAt={updatedAt}
        //isEditing={isEditing}
        handleInputChange={handleInputChange}
      />

      {/* Pass the skus as props to ProducerOrderComponent */}
      <ProducerOrderComponent 
        producerOrders={orderDetails.producerOrders} 
        skus={skus} 
        //isEditing={isEditing} 
        handleInputChange={handleInputChange}
        handlePackedDetailChange={handleInputChange} // If applicable
        orderSummary={orderSummary} 
      />
      <OrderChargesSummaryComponent 
        orderSummary={orderSummary} 
        //isEditing={isEditing} 
        handleInputChange={handleInputChange} 
        skus={orderDetails.producerOrders.skus}
        postcode={deliveryAddress.postcode}
        packedItems={packedItems} // Pass packedItems if required for further operations
      />
    </Container>
  );
};

export default OrderPage;
