import React, { useEffect, useState, useRef } from 'react';
import { Container, Table, FormControl, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebaseConfig';
import { FaSortUp, FaSortDown, FaSort } from 'react-icons/fa';

const SellerOrderList = () => {
  const [producerOrders, setProducerOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [searchTerm, setSearchTerm] = useState(localStorage.getItem('searchTerm') || '');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const navigate = useNavigate();
  const tableRef = useRef(null);
  const initialRender = useRef(true);

  useEffect(() => {
    const fetchProducerOrders = async () => {
      try {
        const getAllProducerOrders = httpsCallable(functions, 'getAllProducerOrders');
        const response = await getAllProducerOrders();
        setProducerOrders(response.data.producerOrders);
        setFilteredOrders(response.data.producerOrders);
      } catch (error) {
        console.error('Error fetching producer orders:', error);
      }
    };

    fetchProducerOrders();
  }, []);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }

    const results = producerOrders.filter((order) =>
      Object.values(order).some(
        (value) =>
          value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    setFilteredOrders(results);
    localStorage.setItem('searchTerm', searchTerm);
  }, [searchTerm, producerOrders]);

  useEffect(() => {
    if (sortConfig.key) {
      let sortedOrders = [...filteredOrders];
      if (sortConfig.direction) {
        sortedOrders.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'asc' ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'asc' ? 1 : -1;
          }
          return 0;
        });
      } else {
        sortedOrders = producerOrders; // If no direction, reset to the original order
      }
      setFilteredOrders(sortedOrders);
    }
  }, [sortConfig, producerOrders, filteredOrders]);

  const handleRowClick = (producerCode) => {
    navigate(`/seller-order-details?sellerCode=${producerCode}`);
  };

  const handleClear = () => {
    setSearchTerm('');
    setFilteredOrders(producerOrders);
    localStorage.removeItem('searchTerm');
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key) {
      if (sortConfig.direction === 'asc') {
        direction = 'desc';
      } else if (sortConfig.direction === 'desc') {
        direction = null;
      }
    }
    setSortConfig({ key, direction });
  };

  const getSortIndicator = (key) => {
    if (sortConfig.key === key) {
      if (sortConfig.direction === 'asc') {
        return <FaSortUp />;
      } else if (sortConfig.direction === 'desc') {
        return <FaSortDown />;
      }
    }
    return <FaSort />;
  };

  return (
    <Container className="content">
      <h1>Seller Orders</h1>
      <div className="d-flex mb-3">
        <FormControl
          type="text"
          placeholder="Search"
          className="me-2"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Button variant="secondary" onClick={handleClear}>
          Clear
        </Button>
      </div>
      <Table striped bordered hover ref={tableRef} className="table-responsive">
        <thead>
          <tr>
            {['seller', 'producerOrderStatus', 'sellerCode', 'createdAt'].map((key) => (
              <th
                key={key}
                onClick={() => handleSort(key)}
                style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}
                className={`col-${key}`}
              >
                <div className="d-flex justify-content-between align-items-center">
                  {key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}&nbsp;&nbsp;
                  {getSortIndicator(key)}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {filteredOrders.map((order) => (
            <tr key={order.producerCode} onClick={() => handleRowClick(order.producerCode)} style={{ cursor: 'pointer' }}>
              <td className="col-seller">{order.seller}</td>
              <td className="col-producerOrderStatus">{order.producerOrderStatus}</td>
              <td className="col-sellerCode">{order.producerCode}</td>
              <td className="col-createdAt">{new Date(order.createdAt._seconds * 1000).toLocaleDateString()}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default SellerOrderList;
