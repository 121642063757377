import React from 'react';
import { Table } from 'react-bootstrap';

const SkuDetailsTable = ({ sku }) => {
  const combinedFields = {
    ...sku.appFields,
    ...sku.shippingFields,
    ...sku.otherFields,
  };

  return (
    <Table bordered size="sm" style={{ fontSize: '12px' }}>
      <tbody>
        {Object.entries(combinedFields).map(([field, value]) => (
          <tr key={field}>
            <td style={{ fontWeight: 'bold', whiteSpace: 'nowrap', padding: '5px' }}>
              {field.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase()).toUpperCase()}
            </td>
            <td style={{ whiteSpace: 'nowrap', padding: '5px' }}>{value?.toString() || 'N/A'}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default SkuDetailsTable;
