// src/utils/skuFieldUtils.js

// Define updatable fields based on your validation schema
export const updatableFields = [
  'skuName', 'productImage', 'subCategory', 'speciesFamily', 'cut', 'packFormat', 'sellerRegionId',
  'sellerId', 'category', 'unitSizeName', 'unitSizeDetail', 'productSizeMeasure', 'process',
  'packType', 'price', 'uom', 'moq', 'grade', 'speciesId', 'farmMethod', 'harvestMethod',
  'certification', 'sustainabilityAssessment', 'showInApp', 'availabilityStatus', 'catchSchedule',
  'collectionSchedule', 'finalLegDeliveryCarrier', 'costPerUnitWeight', 'skuIdentifier',
  'skuDescription', 'product', 'size', 'codeProcess', 'packSizeDetail',
  'packSizeMeasure', 'itemType', 'sequenceNumber', 'productCode', 'isPublic', 'productType',
  'productString'
];

// Function to filter fields that are updatable
export const filterFields = (fields) => {
  return Object.fromEntries(Object.entries(fields).filter(([key]) => updatableFields.includes(key)));
};

// Function to filter the updatable fields in SKU data
export const filterUpdatableFields = (skuData) => {
  return {
    appFields: filterFields(skuData.appFields),
    shippingFields: filterFields(skuData.shippingFields),
    otherFields: filterFields(skuData.otherFields),
  };
};
