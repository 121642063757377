import React, { useEffect, useState } from 'react';
import { Container, Card, Button, Form, Alert, Row, Col, Spinner } from 'react-bootstrap';
import Select from 'react-select';
import { useNavigate, useLocation } from 'react-router-dom';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebaseConfig';  // Import Firebase functions
import { getSkuById, updateSku } from '../services/skuService';
import { FaArrowLeft, FaEdit, FaSave, FaTimes } from 'react-icons/fa';
import '../styles.css';

const SkuDetails = () => {
  
  const [editableSku, setEditableSku] = useState({});
  const [backupSku, setBackupSku] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [saveError, setSaveError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [sellers, setSellers] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSkuAndSellers = async () => {
      const query = new URLSearchParams(location.search);
      const skuId = query.get('skuId');

      if (!skuId) {
        console.error('No skuId found in URL');
        setLoading(false);
        return;
      }

      try {
        const getAllSellers = httpsCallable(functions, 'getAllSellers');
        const [skuData, sellersResult] = await Promise.all([
          getSkuById(skuId),
          getAllSellers(),
        ]);

        if (sellersResult.data.sellers && Array.isArray(sellersResult.data.sellers)) {
          setSellers(sellersResult.data.sellers.map(seller => ({
            value: seller.sellerId,
            label: seller.sellerName,
            sellerRegion: seller.sellerRegion,
            sellerCode: seller.sellerCode,
            sellerState: seller.sellerState,
          })));
        } else {
          setSaveError('Unexpected data format for sellers');
        }

        
        setEditableSku(skuData);
        setBackupSku(skuData);
      } catch (error) {
        console.error('Error fetching SKU and sellers:', error);
        setSaveError('Error fetching SKU and sellers');
      } finally {
        setLoading(false);
      }
    };

    fetchSkuAndSellers();
  }, [location]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditableSku({ ...editableSku, [name]: value });
  };

  const handleSellerChange = (selectedSeller) => {
    setEditableSku({
      ...editableSku,
      sellerId: selectedSeller.value,
      sellerName: selectedSeller.label,
      sellerRegion: selectedSeller.sellerRegion,
      sellerCode: selectedSeller.sellerCode,
      sellerState: selectedSeller.sellerState,
    });
  };

  const handleEdit = () => {
    setBackupSku(editableSku);
    setIsEditing(true);
  };

  const handleCancel = () => {
    setEditableSku(backupSku);
    setIsEditing(false);
  };

  const handleSave = async () => {
    setIsSaving(true);
    setIsSaved(false);
    setSaveError(null);

    try {
      await updateSku(editableSku.skuId, editableSku);
      setIsSaved(true);
      setIsEditing(false);
    } catch (error) {
      console.error('Error saving SKU:', error);
      setSaveError(error.message);
    } finally {
      setIsSaving(false);
    }
  };

  const handleBack = () => {
    navigate('/skus');
  };

  if (loading) {
    return (
      <Container className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  const auditFields = [
    { label: 'SKU ID', key: 'skuId' },
    { label: 'Created Date', key: 'createdDate' },
    { label: 'Last Updated', key: 'lastUpdatedDate' },
  ];

  const basicInformation = [
    { label: 'SKU Name', key: 'skuName' },
    { label: 'In Stock', key: 'isInStock' },
    { label: 'SKU Identifier', key: 'skuIdentifier' },
    { label: 'SKU Description', key: 'skuDescription' },
    { label: 'Species Breed', key: 'speciesBreed' },
  ];

  const packagingAndSize = [
    { label: 'Pack Format', key: 'packFormat' },
    { label: 'Pack Type', key: 'packType' },
    { label: 'Pack Size Detail', key: 'packSizeDetail' },
    { label: 'Pack Size Measure', key: 'packSizeMeasure' },
    { label: 'Unit Size Name', key: 'unitSizeName' },
    { label: 'Size', key: 'size' },
    { label: 'Unit Size Detail', key: 'unitSizeDetail' },
    { label: 'Product Size Measure', key: 'productSizeMeasure' },
  ];

  const sellerInformation = [
    {
      label: 'Seller Name',
      key: 'sellerName',
      render: () => (
        <Select
          options={sellers}
          value={sellers.find(seller => seller.value === editableSku.sellerId) || null}
          onChange={handleSellerChange}
          isDisabled={!isEditing}
        />
      ),
    },
    { label: 'Seller ID', key: 'sellerId' },
    { label: 'Seller Region', key: 'sellerRegion' },
    { label: 'Seller Code', key: 'producerCode' },
    { label: 'Seller State', key: 'sellerState' },
  ];

  const pricingAndOrderInformation = [
    { label: 'Price', key: 'price' },
    { label: 'UOM', key: 'uom' },
    { label: 'MOQ', key: 'moq' },
  ];

  const certificationAndSustainability = [
    { label: 'Certification', key: 'certification' },
    { label: 'Sustainability Assessment', key: 'sustainabilityAssessment' },
    { label: 'Category', key: 'category' },
    { label: 'Sub Category', key: 'subCategory' },
    { label: 'Species Family', key: 'speciesFamily' },
    { label: 'Item Type', key: 'itemType' },
    { label: 'Grade', key: 'grade' },
    { label: 'Harvest Method', key: 'harvestMethod' },
    { label: 'Farm Method', key: 'farmMethod' },
  ];

  const renderFields = (fields) => {
    return (
      <Row>
        {fields.map(({ label, key, render }) => (
          <Col md={6} key={key} className="mb-3">
            <Form.Group>
              <Form.Label>{label}</Form.Label>
              {render ? (
                render()
              ) : (
                <Form.Control
                  type="text"
                  name={key}
                  value={editableSku[key] ?? ''}
                  readOnly={key === 'skuId' || key === 'lastUpdatedDate' || !isEditing}
                  onChange={handleInputChange}
                />
              )}
            </Form.Group>
          </Col>
        ))}
      </Row>
    );
  };

  return (
    <Container className="content">
      <h1>SKU Details</h1>
      <div className="d-flex justify-content-between mb-3">
        <Button variant="secondary" onClick={handleBack} disabled={isSaving}>
          <FaArrowLeft className="me-2 mb-1" />
          Back
        </Button>
        <div>
          {isEditing ? (
            <>
              <Button variant="danger" onClick={handleCancel} disabled={isSaving} className="me-3">
                <FaTimes className="me-2 mb-1" />
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={handleSave}
                disabled={isSaving}
              >
                {isSaving ? (
                  <span className="spinner-border spinner-border-sm"></span>
                ) : (
                  <>
                    <FaSave className="me-2 mb-1" />
                    Save
                  </>
                )}
              </Button>
            </>
          ) : (
            <Button variant="secondary" onClick={handleEdit} disabled={isSaving}>
              <FaEdit className="me-2 mb-1" />
              Edit
            </Button>
          )}
        </div>
      </div>
      {isSaved && <Alert variant="success">Record has been saved</Alert>}
      {saveError && <Alert variant="danger">{saveError}</Alert>}
      {[
        { title: 'Basic Information', fields: basicInformation },
        { title: 'Packaging and Size', fields: packagingAndSize },
        { title: 'Seller Information', fields: sellerInformation },
        { title: 'Pricing and Order Information', fields: pricingAndOrderInformation },
        { title: 'Certification and Sustainability', fields: certificationAndSustainability },
        { title: 'Audit Fields', fields: auditFields },
      ].map((section, idx) => (
        <Card key={idx} className="mb-3">
          <Card.Header>{section.title}</Card.Header>
          <Card.Body>
            <Form>{renderFields(section.fields)}</Form>
          </Card.Body>
        </Card>
      ))}
    </Container>
  );
};

export default SkuDetails;
