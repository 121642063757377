import React, { useEffect, useState } from 'react';
import { Container, Card, Button, Form, Alert, Row, Col, Spinner } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { getSpeciesById, updateSpecies } from '../services/speciesService'; // Import species service
import { FaArrowLeft, FaEdit, FaSave, FaTimes } from 'react-icons/fa';
import '../styles.css';

const SpeciesDetails = () => {
  const [editableSpecies, setEditableSpecies] = useState({});
  const [backupSpecies, setBackupSpecies] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [saveError, setSaveError] = useState(false);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSpeciesDetails = async () => {
      const query = new URLSearchParams(location.search);
      const speciesId = query.get('speciesId');

      try {
        const speciesData = await getSpeciesById(speciesId);

        if (!speciesData) {
          throw new Error('Species data not found in the response');
        }

        setEditableSpecies(speciesData);
        setBackupSpecies(speciesData);
      } catch (error) {
        console.error('Error fetching Species details:', error);
        setSaveError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchSpeciesDetails();
  }, [location]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditableSpecies({ ...editableSpecies, [name]: value });
  };

  const handleEdit = () => {
    setBackupSpecies(editableSpecies); // Save a copy of the current state
    setIsEditing(true);
  };

  const handleCancel = () => {
    setEditableSpecies(backupSpecies); // Restore the backup state
    setIsEditing(false);
  };

  const handleSave = async () => {
    setIsSaving(true);
    setIsSaved(false);
    setSaveError(false);
    try {
      await updateSpecies(editableSpecies.speciesId, {
        speciesBreed: editableSpecies.speciesBreed,
        binomialName: editableSpecies.binomialName,
        otherNames: editableSpecies.otherNames,
        speciesDescription: editableSpecies.speciesDescription,
        descriptionShort: editableSpecies.descriptionShort,
      });
      setIsSaved(true);
    } catch (error) {
      console.error('Error saving Species:', error);
      setSaveError(true);
    } finally {
      setIsSaving(false);
      setIsEditing(false);
    }
  };

  const handleBack = () => {
    navigate('/species');
  };

  if (loading) {
    return (
      <Container className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  const speciesInformation = [
    { label: 'Species Breed', key: 'speciesBreed', editable: true },
    { label: 'Binomial Name', key: 'binomialName', editable: true },
    { label: 'Other Names', key: 'otherNames', editable: true },
    { label: 'Short Description', key: 'descriptionShort', as: 'textarea', rows: 3, editable: true },
    { label: 'Description', key: 'speciesDescription', as: 'textarea', rows: 6, editable: true },
  ];

  const auditFields = [
    { label: 'Species ID', key: 'speciesId', editable: false },
  ];

  const renderFields = (fields) => {
    return (
      <Row>
        {fields.map(({ label, key, value, as, rows, editable }) => (
          <Col md={as ? 12 : 6} key={key} className="mb-3">
            <Form.Group>
              <Form.Label>{label}</Form.Label>
              <Form.Control
                as={as || 'input'}
                rows={rows || 1}
                name={key}
                value={value || editableSpecies[key] || ''}
                readOnly={!isEditing || !editable}
                onChange={editable ? handleInputChange : undefined}
              />
            </Form.Group>
          </Col>
        ))}
      </Row>
    );
  };

  return (
    <Container className="content">
      <h1>Species Details</h1>
      <div className="d-flex justify-content-between mb-3">
        <Button variant="secondary" onClick={handleBack}>
          <FaArrowLeft className="me-2 mb-1" />
          Back
        </Button>
        <div>
          {isEditing ? (
            <>
              <Button variant="danger" onClick={handleCancel} disabled={isSaving} className="me-3">
                <FaTimes className="me-2 mb-1" />
                Cancel
              </Button>
              <Button 
                variant="primary" 
                onClick={handleSave} 
                disabled={isSaving}
              >
                {isSaving ? (
                  <span className="spinner-border spinner-border-sm"></span>
                ) : (
                  <>
                    <FaSave className="me-2 mb-1" />
                    Save
                  </>
                )}
              </Button>
            </>
          ) : (
            <Button variant="secondary" onClick={handleEdit} disabled={isSaving}>
              <FaEdit className="me-2 mb-1" />
              Edit
            </Button>
          )}
        </div>
      </div>
      {isSaved && <Alert variant="success">Record has been saved</Alert>}
      {saveError && <Alert variant="danger">Could not save record</Alert>}

      <Card className="mb-3">
        <Card.Header>Species Information</Card.Header>
        <Card.Body>
          <Form>{renderFields(speciesInformation)}</Form>
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Header>Audit Information</Card.Header>
        <Card.Body>
          <Form>{renderFields(auditFields)}</Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default SpeciesDetails;
