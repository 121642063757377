// themeUtils.js

export const setDarkMode = (mode) => {
  if (mode === 'dark') {
    document.documentElement.setAttribute('data-bs-theme', 'dark');

  } else if (mode === 'light') {
    document.documentElement.setAttribute('data-bs-theme', 'light');

  } else {
    // Auto mode
    const prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)").matches;
    document.documentElement.setAttribute('data-bs-theme', prefersDarkScheme ? 'dark' : 'light');

  }
  localStorage.setItem('theme', mode);
};

export const getStoredTheme = () => {
  return localStorage.getItem('theme') || 'auto';
};
