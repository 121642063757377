import React, { useEffect, useState, useRef } from 'react';
import { Container, Table, FormControl, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FaSortUp, FaSortDown, FaSort } from 'react-icons/fa';
import { getAllOrders } from '../services/orderService';
import '../styles.css';

const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp);

  // Define options for formatting
  const options = {
    hour: '2-digit',
    minute: '2-digit',
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  };

  // Convert date to formatted string
  return date.toLocaleString('en-GB', options).replace(',', '');
};

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [searchTerm, setSearchTerm] = useState(localStorage.getItem('searchTerm') || '');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const tableRef = useRef(null);
  const initialRender = useRef(true);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const ordersData = await getAllOrders(); // Fetch orders using the service
        const formattedOrders = ordersData.map(order => ({
          ...order,
          orderTotal: order.orderSummary.orderTotal,
          buyerCompanyName: order.orderDetails.buyerCompanyName
        }));
        setOrders(formattedOrders);
        setFilteredOrders(formattedOrders);
      } catch (error) {
        console.error('Error fetching orders:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchOrders();
  }, []);
  

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }

    const results = orders.filter((order) =>
      Object.values(order).some(
        (value) =>
          value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    setFilteredOrders(results);
    localStorage.setItem('searchTerm', searchTerm);
  }, [searchTerm, orders]);

  useEffect(() => {
    if (sortConfig.key) {
      let sortedOrders = [...filteredOrders];
      if (sortConfig.direction) {
        sortedOrders.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'asc' ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'asc' ? 1 : -1;
          }
          return 0;
        });
      } else {
        sortedOrders = orders; // If no direction, reset to the original order
      }
      setFilteredOrders(sortedOrders);
    }
  }, [sortConfig, orders, filteredOrders]);

  const handleRowClick = (orderId) => {
    navigate(`/order-details?orderId=${orderId}`);
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key) {
      if (sortConfig.direction === 'asc') {
        direction = 'desc';
      } else if (sortConfig.direction === 'desc') {
        direction = null;
      }
    }
    setSortConfig({ key, direction });
  };

  const getSortIndicator = (key) => {
    if (sortConfig.key === key) {
      if (sortConfig.direction === 'asc') {
        return <FaSortUp />;
      } else if (sortConfig.direction === 'desc') {
        return <FaSortDown />;
      }
    }
    return <FaSort />;
  };

  if (loading) {
    return (
      <Container className="content d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  return (
    <Container className="content">
      <h1>Orders Page</h1>
      <div className="d-flex mb-3">
        <FormControl
          type="text"
          placeholder="Search"
          className="me-2"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <Table striped bordered hover ref={tableRef} className="table-responsive">
      <thead>
  <tr>
    <th onClick={() => handleSort('orderId')} style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>
      <div className="d-flex justify-content-between align-items-center">
        Order ID&nbsp;&nbsp;
        {getSortIndicator('orderId')}
      </div>
    </th>
    <th onClick={() => handleSort('buyerCompanyName')} style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>
      <div className="d-flex justify-content-between align-items-center">
        Buyer Company Name&nbsp;&nbsp;
        {getSortIndicator('buyerCompanyName')}
      </div>
    </th>
    <th onClick={() => handleSort('orderTotal')} style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>
      <div className="d-flex justify-content-between align-items-center">
        Order Total&nbsp;&nbsp;
        {getSortIndicator('orderTotal')}
      </div>
    </th>
    <th onClick={() => handleSort('orderStatus')} style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>
      <div className="d-flex justify-content-between align-items-center">
        Status&nbsp;&nbsp;
        {getSortIndicator('orderStatus')}
      </div>
    </th>
    <th onClick={() => handleSort('createdAt')} style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>
      <div className="d-flex justify-content-between align-items-center">
        Created&nbsp;&nbsp;
        {getSortIndicator('createdAt')}
      </div>
    </th>
    <th onClick={() => handleSort('updatedAt')} style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>
      <div className="d-flex justify-content-between align-items-center">
        Last Updated&nbsp;&nbsp;
        {getSortIndicator('updatedAt')}
      </div>
    </th>


  </tr>
</thead>

<tbody>
  {filteredOrders.map((order) => (
    <tr key={order.orderId} onClick={() => handleRowClick(order.orderId)} style={{ cursor: 'pointer' }}>
      <td>{order.orderId}</td>
      <td>{order.buyerCompanyName}</td> {/* Display buyerCompanyName */}
      <td>${order.orderTotal.toFixed(2)}</td> {/* Display orderTotal */}
      <td>{order.orderStatus}</td>
      <td>{formatTimestamp(order.createdAt)}</td>
      <td>{formatTimestamp(order.updatedAt)}</td>


    </tr>
  ))}
</tbody>

      </Table>
    </Container>
  );
};

export default Orders;
