import { functions } from "../firebaseConfig";  // Assuming firebaseConfig correctly exports initialized Firebase app
import { httpsCallable } from 'firebase/functions';

/**
 * Fetches an order by its ID using the callable function 'getOrderById'.
 * @param {string} orderId - The ID of the order to be fetched.
 * @returns {Promise<object>} - The order data if successful.
 * @throws {Error} - Throws an error if the function call fails.
 */
export const getOrderById = async (orderId) => {
  try {
    const getOrderByIdCallable = httpsCallable(functions, 'getOrderById');
    const response = await getOrderByIdCallable({ orderId });

    if (response.data && response.data.order) {
      return response.data.order;
    } else {
      throw new Error('Order data not found in the response');
    }
  } catch (error) {
    console.error('Error fetching order:', error);
    throw error;
  }
};

/**
 * Fetches all orders using the callable function 'getAllOrders' and sorts them by the updated date, newest first.
 * @returns {Promise<Array>} - An array of sorted order data if successful.
 * @throws {Error} - Throws an error if the function call fails.
 */
export const getAllOrders = async () => {
  try {
    const getAllOrdersCallable = httpsCallable(functions, 'getAllOrders');
    const response = await getAllOrdersCallable();

    if (response.data && response.data.orders) {
      // Sort the orders by the updatedAt date, newest first
      return response.data.orders.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    } else {
      throw new Error('Orders data not found in the response');
    }
  } catch (error) {
    console.error('Error fetching all orders:', error);
    throw error;
  }
};

export const updateOrder = async () => {

    return true
  
};

// Define the updatePackedItems service
export const updatePackedItems = async ({ orderId, producerOrderId, packedSkus }) => {
  try {
    // Reference the callable function 'updatePackedItems' from your backend
    const updatePackedItemsCallable = httpsCallable(functions, 'updatePackedItems');

    // Call the backend function with the required data
    const response = await updatePackedItemsCallable({
      orderId,
      producerOrderId,
      packedSkus,
    });

    // Handle successful response
    if (response.data.result.success) {
      console.log('Update successful:', response.data);
      return response.data.result;
    } else {
      // Handle unsuccessful response
      throw new Error('Failed to update packed items.');
    }
  } catch (error) {
    // Handle errors from the function call
    console.error('Error calling updatePackedItems:', error.message);
    throw error;
  }
};


export const getOrderSummary = async (items, postcode) => {
  try {
    const getOrderSummaryCallable = httpsCallable(functions, 'getOrderSummary');
    const response = await getOrderSummaryCallable({ items, postcode });
    return response.data.orderSummary;
  } catch (error) {
    console.error('Error generating order summary:', error);
    throw error;
  }
}
