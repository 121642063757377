// components/SkuCard.js

import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';

const SkuCard = ({ sku, onClick }) => (
  <Card className="sku-card mb-3" style={{ width: '100%' }} onClick={onClick}>
    <Row className="g-0">
      <Col xs={3} className="d-flex align-items-center justify-content-center" style={{ maxWidth: '150px' }}>
        <img
          src={`https://assets.harveststack.co/pictures/skus/medium/${sku.appFields.productImage}`}
          alt={sku.appFields.skuName}
          className="img-fluid"
          style={{
            width: '100px',
            height: '100px',
            objectFit: 'contain',
            borderRadius: '4px',
            marginLeft: '10px',
          }}
        />
      </Col>
      <Col xs={9} style={{ padding: '10px', fontSize: '10pt' }}>
        <Card.Body style={{ paddingTop: '0pt', paddingBottom: '0pt' }}>
          <Card.Title style={{ fontSize: '10pt' }}>
            {sku.appFields.skuName} ({sku.appFields.cut})
          </Card.Title>
          <Card.Text style={{ fontSize: '10pt' }}>
            {sku.appFields.sellerName} ({sku.appFields.category})
            <br />
            {sku.appFields.unitSizeName} - {sku.appFields.unitSizeDetail} - {sku.appFields.productSizeMeasure} - {sku.appFields.packType}
            <br />
            Process: {sku.appFields.process || 'N/A'}
            <br />
            A$ {sku.appFields.price} / {sku.appFields.uom}
            <br />
            Availability: {sku.appFields.availabilityStatus}
            <br />
            In App: {sku.appFields.showInApp ? 'Yes' : 'No'}
          </Card.Text>
        </Card.Body>
      </Col>
    </Row>
  </Card>
);

export default SkuCard;
