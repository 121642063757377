// src/services/speciesService.js

import { httpsCallable } from "firebase/functions";
import { functions } from "../firebaseConfig";  // Assuming firebaseConfig correctly exports initialized Firebase app

export const getAllSpecies = async () => {
  const getAllSpeciesCallable = httpsCallable(functions, 'getAllSpecies');
  const response = await getAllSpeciesCallable();
  return response.data;  // This should return an object like { result: { success: true, species: [...] } }
};

export const getSpeciesById = async (speciesId) => {
  const getSpeciesByIdCallable = httpsCallable(functions, 'getSpeciesById');
  const response = await getSpeciesByIdCallable({ speciesId });
  return response.data.species;
};


export const updateSpecies = async (speciesId, speciesData) => {
  const updateSpeciesCallable = httpsCallable(functions, 'updateSpecies');
  const response = await updateSpeciesCallable({ speciesId, ...speciesData });
  return response.data;
};


// NEW ONES

export const getSpecies = async (scope) => {
  try {
    const getSpecies = httpsCallable(functions, 'getSpecies');
    const response = await getSpecies({ scope }); // Pass the scope parameter
    return response.data;
  } catch (error) {
    console.error('Error fetching species:', error);
    throw new Error('Failed to fetch species');
  }
};