import React, { useEffect, useState } from 'react';
import { Container, Table } from 'react-bootstrap';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebaseConfig';  // Import the functions object from your firebaseConfig
import '../styles.css';

const PackingSlips = () => {
  const [packingSlips, setPackingSlips] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPackingSlips = async () => {
      const getPackingSlips = httpsCallable(functions, 'getPackingSlips');
      
      try {
        const result = await getPackingSlips();
        setPackingSlips(result.data.packingSlips);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching packing slips:', error);
        setError('Error fetching packing slips');
        setLoading(false);
      }
    };

    fetchPackingSlips();
  }, []);

  if (loading) {
    return <Container className="content"><p>Loading...</p></Container>;
  }

  if (error) {
    return <Container className="content"><p>{error}</p></Container>;
  }

  return (
    <Container className="content">
      <h1>Packing Slips</h1>
      {Object.keys(packingSlips).map(producerCode => (
        <div key={producerCode}>
          <h2>Producer: {producerCode}</h2>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Product name</th>
                <th>Restaurant name</th>
                <th>Weight (kg/box)</th>
                <th>Order ID</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(packingSlips[producerCode]).map(companyId => (
                packingSlips[producerCode][companyId].map(item => (
                  <tr key={item.producerOrderId}>
                    <td>{item.skuName}</td>
                    <td>{companyId}</td>
                    <td>{item.quantity}{item.uom}</td>
                    <td style={{ fontFamily: 'monospace' }}>{item.producerOrderId}</td>
                  </tr>
                ))
              ))}
            </tbody>
          </Table>
        </div>
      ))}
    </Container>
  );
};

export default PackingSlips;
