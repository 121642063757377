// Options based on your Joi schema
const options = {
  subCategory: [
    { value: 'game', label: 'Game' },
    { value: 'meat', label: 'Meat' },
    { value: 'poultry', label: 'Poultry' },
    { value: 'seafood', label: 'Seafood' }
  ],
  speciesFamily: [
    { value: 'anguilliformes', label: 'Anguilliformes' },
    { value: 'cattle', label: 'Cattle' },
    { value: 'chicken', label: 'Chicken' },
    { value: 'crustacean', label: 'Crustacean' },
    { value: 'echinodermate', label: 'Echinodermate' },
    { value: 'finfish', label: 'Finfish' },
    { value: 'game birds', label: 'Game Birds' },
    { value: 'macropod', label: 'Macropod' },
    { value: 'mollusk', label: 'Mollusk' },
    { value: 'rodentia', label: 'Rodentia' },
    { value: 'sheep', label: 'Sheep' }
  ],
  packFormat: [
    { value: 'chilled', label: 'Chilled' },
    { value: 'frozen', label: 'Frozen' },
    { value: 'live', label: 'Live' }
  ],
  category: [
    { value: 'farmed', label: 'Farmed' },
    { value: 'wild', label: 'Wild' }
  ],
  productSizeMeasure: [
    { value: 'gm', label: 'Grams (gm)' },
    { value: 'kg', label: 'Kilogram (kg)' },
    { value: 'mm', label: 'Millimeter (mm)' }
  ],
  packType: [
    { value: 'bag', label: 'Bag' },
    { value: 'box', label: 'Box' },
    { value: 'jar', label: 'Jar' },
    { value: 'loose', label: 'Loose' },
    { value: 'tray', label: 'Tray' },
    { value: 'vacuum pouch', label: 'Vacuum Pouch' }
  ],
  uom: [
    { value: 'dz', label: 'Dozen (dz)' },
    { value: 'ea', label: 'Each (ea)' },
    { value: 'kg', label: 'Kilogram (kg)' },
    { value: 'ltr', label: 'Liter (ltr)' }
  ],
  grade: [
    { value: 'AAA', label: 'AAA' },
    { value: 'Infinity Blue', label: 'Infinity Blue' },
    { value: 'Ora King', label: 'Ora King' },
    { value: 'Premium', label: 'Premium' }
  ],
  harvestMethod: [
    { value: 'bottom trawl', label: 'Bottom Trawl' },
    { value: 'fyke net', label: 'Fyke Net' },
    { value: 'hand dive', label: 'Hand Dive' },
    { value: 'hand line', label: 'Hand Line' },
    { value: 'hand rake', label: 'Hand Rake' },
    { value: 'long line', label: 'Long Line' },
    { value: 'mesh/gill net, ringing seine', label: 'Mesh/Gill Net, Ringing Seine' },
    { value: 'pot', label: 'Pot' },
    { value: 'purse seine', label: 'Purse Seine' },
    { value: 'trap', label: 'Trap' },
    { value: 'wild shot', label: 'Wild Shot' }
  ],
  certification: [
    { value: 'BAP', label: 'BAP' },
    { value: 'Halal, A+, BAP', label: 'Halal, A+, BAP' },
    { value: 'MSC', label: 'MSC' },
    { value: 'PROOF', label: 'PROOF' }
  ],
  availabilityStatus: [
    { value: 'IN_STOCK', label: 'In Stock' },
    { value: 'EXPECTED_TO_HARVEST', label: 'Expected to Harvest' },
    { value: 'UNAVAILABLE', label: 'Unavailable' }
  ],
  packSizeMeasure: [
    { value: 'dz', label: 'Dozen (dz)' },
    { value: 'ea', label: 'Each (ea)' },
    { value: 'gr', label: 'Grams (gr)' },
    { value: 'kg', label: 'Kilogram (kg)' },
    { value: 'ltr', label: 'Liter (ltr)' }
  ],
  itemType: [
    { value: 'beef', label: 'Beef' },
    { value: 'broiler', label: 'Broiler' },
    { value: 'bull', label: 'Bull' },
    { value: 'cockerel', label: 'Cockerel' },
    { value: 'lamb', label: 'Lamb' }
  ],
  showInApp: [
    { value: true, label: 'True' },
    { value: false, label: 'False' }
  ],
  isPublic: [
    { value: true, label: 'True' },
    { value: false, label: 'False' }
  ],
};

export default options;
