import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import Select from 'react-select'; // Import react-select

const OrderOverviewComponent = ({
  orderDetails,
  deliveryAddress,
  buyerCompanyName,
  createdAt,
  orderId,
  orderStatus,
  updatedAt,
  handleOrderStatusChange,
  isEditing, // Add this prop to control editing mode
}) => {
  // Options for order status
  const orderStatusOptions = [
    { value: 'BASKET', label: 'BASKET' },
    { value: 'CHECKOUT', label: 'CHECKOUT' },
    { value: 'PAYMENT_RECEIVED', label: 'PAYMENT_RECEIVED' },
    { value: 'PAYMENT_FAILED', label: 'PAYMENT_FAILED' },
    { value: 'PROCESSING', label: 'PROCESSING' },
    { value: 'COMPLETE', label: 'COMPLETE' },
  ];

  // Find the selected order status option based on the current orderStatus value
  const selectedStatus = orderStatusOptions.find((option) => option.value === orderStatus);

  return (
    <Card className="mb-3">
      <Card.Header>Overview</Card.Header>
      <Card.Body>
        <Row className="g-3 d-flex align-items-stretch">
          <Col xs={12} md={6} className="d-flex">
            <Card className="flex-fill rounded" style={{ borderRight: '1px solid #dee2e6' }}>
              <Card.Body>
                <p>
                  <strong>Company Name:</strong> {buyerCompanyName}
                </p>
                <p><strong>Address Line 1:</strong> {deliveryAddress.addressLine1}</p>
                <p><strong>Address Line 2:</strong> {deliveryAddress.addressLine2}</p>
                <p><strong>City:</strong> {deliveryAddress.city}</p>
                <p><strong>Region:</strong> {deliveryAddress.region}</p>
                <p><strong>Postcode:</strong> {deliveryAddress.postcode}</p>
                <p><strong>Country:</strong> {deliveryAddress.country}</p>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={6} className="d-flex">
            <Card className="flex-fill rounded" style={{ borderLeft: '1px solid #dee2e6' }}>
              <Card.Body>
                <div className="d-flex align-items-center mb-2">
                  <strong className="me-2">Order Status:</strong>
                  <Select
                    options={orderStatusOptions}
                    value={selectedStatus}
                    onChange={handleOrderStatusChange}
                    className="flex-grow-1"
                    isDisabled={!isEditing} // Disable the select when not in edit mode
                  />
                </div>
                <p className="mt-2">
                  <strong>Payment Method:</strong> {orderDetails.paymentMethod}
                </p>
                <p>
                  <strong>Order ID:</strong> {orderId}
                </p>
                <p>
                  <strong>Created At:</strong> {new Date(createdAt).toLocaleString()}
                </p>
                <p>
                  <strong>Updated At:</strong> {new Date(updatedAt).toLocaleString()}
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default OrderOverviewComponent;
