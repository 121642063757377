import React, { useEffect, useState } from 'react';
import { Container, Card, Button, Form, Alert, Row, Col, Spinner } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaArrowLeft, FaEdit, FaSave, FaTimes } from 'react-icons/fa';
import { getSellerById, updateSeller } from '../services/sellerService'; // Import seller service
import { getRegionById } from '../services/regionService'; // Import region service
import '../styles.css';

const SellerDetails = () => {
  const [editableSeller, setEditableSeller] = useState({});
  const [backupSeller, setBackupSeller] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [saveError, setSaveError] = useState(null); // To store error message
  const [loading, setLoading] = useState(true);
  const [regionName, setRegionName] = useState(''); // State to store the resolved region name
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSellerDetails = async () => {
      const query = new URLSearchParams(location.search);
      const sellerId = query.get('sellerId');

      if (!sellerId) {
        console.error('No sellerId found in URL');
        setLoading(false);
        return;
      }

      try {
        const sellerData = await getSellerById(sellerId);

        if (!sellerData) {
          throw new Error('Seller data not found in the response');
        }
        
        setEditableSeller(sellerData);
        setBackupSeller(sellerData); // Store a backup for canceling edits

        // Fetch the region name based on the seller's region ID
        if (sellerData.sellerRegion) {
          const regionData = await getRegionById(sellerData.sellerRegion);
          setRegionName(regionData?.regionName || 'Unknown Region');
        }

      } catch (error) {
        console.error('Error fetching Seller details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSellerDetails();
  }, [location]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditableSeller({ ...editableSeller, [name]: value });
  };

  const handleEdit = () => {
    setBackupSeller(editableSeller); // Store the current state in case of cancel
    setIsEditing(true);
  };

  const handleCancel = () => {
    setEditableSeller(backupSeller); // Restore the backup state
    setIsEditing(false);
  };

  const handleSave = async () => {
    setIsSaving(true);
    setIsSaved(false);
    setSaveError(null);

    try {
      await updateSeller(editableSeller.sellerId, {
        sellerName: editableSeller.sellerName,
        sellerDescription: editableSeller.sellerDescription,
        shortDescription: editableSeller.shortDescription,
      });
      setIsSaved(true);
      setIsEditing(false);
    } catch (error) {
      console.error('Error saving Seller:', error);
      setSaveError(error.message); // Store the error message
    } finally {
      setIsSaving(false);
    }
  };

  const handleBack = () => {
    navigate('/sellers');
  };

  if (loading) {
    return (
      <Container className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  const basicInformation = [
    { label: 'Seller Name', key: 'sellerName', editable: true },
    { label: 'Seller Code', key: 'sellerCode', editable: false },
    { label: 'Region', key: 'sellerRegion', value: regionName, editable: false }, // Display the region name
    { label: 'Country', key: 'sellerCountry', editable: false },
    { label: 'State', key: 'sellerState', editable: false },
  ];

  const contentInformation = [
    { label: 'Short Description', key: 'shortDescription', as: 'textarea', rows: 3, editable: true },
    { label: 'Description', key: 'sellerDescription', as: 'textarea', rows: 6, editable: true },
  ];

  const auditFields = [
    { label: 'Seller ID', key: 'sellerId', editable: false },
  ];

  const renderFields = (fields) => {
    return (
      <Row>
        {fields.map(({ label, key, value, as, rows, editable }) => (
          <Col md={as ? 12 : 6} key={key} className="mb-3">
            <Form.Group>
              <Form.Label>{label}</Form.Label>
              <Form.Control
                as={as || 'input'}
                rows={rows || 1}
                name={key}
                value={value || editableSeller[key] || ''}
                readOnly={!isEditing || !editable}
                onChange={editable ? handleInputChange : undefined}
              />
            </Form.Group>
          </Col>
        ))}
      </Row>
    );
  };

  return (
    <Container className="content">
      <h1>Seller Details</h1>
      <div className="d-flex justify-content-between mb-3">
        <Button variant="secondary" onClick={handleBack} disabled={isSaving}>
          <FaArrowLeft className="me-2 mb-1" />
          Back
        </Button>
        <div>
          {isEditing ? (
            <>
              <Button variant="danger" onClick={handleCancel} disabled={isSaving} className="me-3">
                <FaTimes className="me-2 mb-1" />
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={handleSave}
                disabled={isSaving}
              >
                {isSaving ? (
                  <span className="spinner-border spinner-border-sm"></span>
                ) : (
                  <>
                    <FaSave className="me-2 mb-1" />
                    Save
                  </>
                )}
              </Button>
            </>
          ) : (
            <Button variant="secondary" onClick={handleEdit} disabled={isSaving}>
              <FaEdit className="me-2 mb-1" />
              Edit
            </Button>
          )}
        </div>
      </div>
      {isSaved && <Alert variant="success">Record has been saved</Alert>}
      {saveError && <Alert variant="danger">{saveError}</Alert>}

      <Card className="mb-3">
        <Card.Header>Basic Information</Card.Header>
        <Card.Body>
          <Form>{renderFields(basicInformation)}</Form>
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Header>Content</Card.Header>
        <Card.Body>
          <Form>{renderFields(contentInformation)}</Form>
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Header>Audit Information</Card.Header>
        <Card.Body>
          <Form>{renderFields(auditFields)}</Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default SellerDetails;
